<template>
  <div>
    <b-modal
      id="tax-types-modal"
      v-model="showTaxTypeEditor"
      size="lg"
      hide-footer
      :title="$t('Vergi Türleri')"
    >
      <tax-types
        :show="showTaxTypeEditor"
        @dataChanged="taxTypeDataChanged"
      /></b-modal>
    <!-- depolar modal -->
    <b-modal
      id="stores-modal"
      v-model="showStoreEditor"
      size="xl"
      hide-footer
      :title="$t('Depo Tanımları')"
    >
      <stores
        :show="showStoreEditor"
        @dataChanged="storeDataChanged"
      /></b-modal>
    <!-- Kategorü düzenleme modal -->
    <b-modal
      id="categories-modal"
      v-model="showCategoriyEditor"
      size="lg"
      hide-footer
      :title="$t('Ürün Kategorileri')"
    >
      <category-list
        :show="showCategoriyEditor"
        @dataChanged="categoryDataChanged"
      /></b-modal>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        v-if="productData"
        class="p-2"
        @submit.prevent="handleSubmit(saveData)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col md="8">
            <b-tabs
              pills
              nav-class="nav-pill-secondary"
            >
              <b-tab
                :title="$t('Türkçe Ürün Bilgileri')"
                active
              >
                <!-- Ürün Adı -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Ürün Adı')"
                  rules="required|min:2"
                >
                  <b-form-group
                    :label="$t('Ürün Adı')"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="productData.name"
                      autofocus
                      :readonly="productData.creator!=1"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-form-group
                  :label="$t('Ürün Açıklaması')"
                  label-for="description"
                >
                  <quill-editor
                    id="description"
                    v-model="productData.description"
                    :options="snowOption"
                  />
                </b-form-group>

              </b-tab>
              <b-tab :title="$t('İngilizce Ürün Bilgilleri (Opsiyonel)')">
                <!-- Ürün İngilizce -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Ürün Adı')"
                  rules="min:2"
                >
                  <b-form-group
                    :label="$t('Ürün Adı')"
                    label-for="name_en"
                  >
                    <b-form-input
                      id="name_en"
                      v-model="productData.name_en"
                      autofocus
                      :readonly="productData.creator!=1"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-form-group
                  :label="$t('Ürün Açıklaması')"
                  label-for="description_en"
                >
                  <quill-editor
                    id="description_en"
                    v-model="productData.description_en"
                    :options="snowOption"
                  />
                </b-form-group>
              </b-tab>

            </b-tabs>
            <b-form-group>
              <b-form-checkbox
                id="is_ecommerce"
                v-model="productData.is_ecommerce"
                class="custom-control-primary"
                name="check-button"
                :value="1"
                :unchecked-value="0"
              >
                {{ $t('B2B Üzeirnde Listelensin') }}
              </b-form-checkbox>

            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t('Kaydet') }}
              </b-button>
            </div>

          </b-col>
          <b-col
            md="4"
          >
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <feather-icon
                    icon="InfoIcon"
                    size="18"
                  />
                  <span class="align-middle ml-50">{{ $t('Ürün Genel Bilgileri') }}</span>
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <!-- Taslak mı  -->
                <b-form-group>
                  <b-form-checkbox
                    id="is_draft"
                    v-model="productData.is_draft"
                    class="custom-control-primary"
                    name="check-button"
                    :value="1"
                    :unchecked-value="0"
                  >
                    {{ $t('Taslak (Taslak olan ürünler kullanılamaz)') }}
                  </b-form-checkbox>

                </b-form-group>
                <!-- Stok Kodu -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Stok Kodu')"
                  rules="required|min:2"
                >
                  <b-form-group
                    :label="$t('Stok Kodu')"
                    label-for="code"
                  >
                    <b-form-input
                      id="name"
                      v-model="productData.code"
                      autofocus
                      :readonly="productData.creator!=1"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- ürün stokları -->
                <validation-provider

                  :name="$t('Stok')"
                  rules="required"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"

                      variant="flat-primary"
                      @click="showStoreEditor=true"
                    >
                      <feather-icon icon="EditIcon" />
                      {{ $t('Depoları Düzenle') }}
                    </b-button>
                  </div>

                  <b-row
                    v-for="(item) in productData.stock_data"
                    :id="item.id"
                    :key="item.id"
                    ref="row"
                  >

                    <!-- Item Name -->
                    <b-col md="12">
                      <b-form-group>
                        <b-input-group>
                          <b-form-input
                            id="item-name"
                            v-model="item.store_name"
                            type="text"
                            :placeholder="$t('Depo')"
                            :readonly="true"
                          />
                          <b-input-group-append>
                            <b-form-input
                              id="item-stock"
                              v-model="item.stock"
                              type="number"
                              :placeholder="$t('Stok')"
                            />
                          </b-input-group-append>
                        </b-input-group>

                      </b-form-group>
                    </b-col>

                  </b-row>
                </validation-provider>
                <!-- Fiyat -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Fiyat')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Fiyat')"
                    label-for="price"
                  >
                    <b-input-group>
                      <cleave
                        id="price"
                        v-model="productData.price"
                        size="lg"
                        :state="getValidationState(validationContext)"
                        :readonly="productData.creator!=1"
                        class="form-control"
                        right
                        :raw="false"
                        :options="priceInputConfig"
                      />
                      <b-input-group-append>
                        <b-form-select
                          v-model="productData.currency_type"
                          :options="currencyOptions"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Birim -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Birim')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Birim')"
                    label-for="unit_type"
                  >

                    <b-form-select
                      v-model="productData.unit_type"
                      :options="unitOptions"
                      :state="getValidationState(validationContext)"
                      :readonly="productData.creator!=1"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Vergi Türü -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Vergi Türü')"
                  rules="required"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.tax-types-modal
                      variant="flat-primary"
                    >
                      <feather-icon icon="EditIcon" />
                      {{ $t('Vergi Türlerini Düzenle') }}
                    </b-button>
                  </div>

                  <b-form-group
                    :label="$t('Vergi Türü')"
                    label-for="tax_type"
                  >

                    <b-form-select
                      v-model="productData.tax_type"
                      :options="taxTypesData"
                      :state="getValidationState(validationContext)"
                      :readonly="productData.creator!=1"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Kategori -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Kagetori')"
                  rules="required"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.categories-modal
                      variant="flat-primary"
                      type="button"
                    >
                      <feather-icon icon="EditIcon" />
                      {{ $t('Kategorileri Düzenle') }}
                    </b-button>
                  </div>

                  <b-form-group
                    :label="$t('Kategori')"
                    label-for="category_id"
                  >

                    <b-form-select
                      v-model="productData.category_id"
                      :options="categoriesData"
                      :state="getValidationState(validationContext)"
                      :readonly="productData.creator!=1"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-card-body>
            </b-card>
          </b-col>

        </b-row>
      </b-form>

    </validation-observer>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BTab, BTabs, BModal, VBModal, BInputGroupAppend, BFormSelect, BInputGroup, BCard, BCardBody, BCardHeader, BCardTitle, BFormInput, BFormInvalidFeedback, BRow, BCol, BButton, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { ref } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
import taxTypes from './product-tax-types/TaxTypes.vue'
import stores from './product-stores/Stores.vue'
import categoryList from '../product-categories/CategoryList.vue'

export default {
  components: {
    BForm,
    BFormCheckbox,
    BRow,
    BTab,
    BTabs,
    taxTypes,
    stores,
    BModal,
    BFormSelect,
    BCardBody,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    quillEditor,
    categoryList,
    Cleave,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isEditProductSidebarActive',
    event: 'update:is-edit-product-sidebar-active',
  },
  props: {
    blankProductData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      showTaxTypeEditor: false,
      showCategoriyEditor: false,
      showStoreEditor: false,

    }
  },
  watch: {
    showCategoriyEditor(val) {
      if (val === false) {
        this.fetchCategories()
      }
    },
  },
  methods: {
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    makeproductData() {
      this.productData = JSON.parse(JSON.stringify(this.blankProductData))
    },

    taxTypeDataChanged(taxTypesData) {
      this.taxTypesData = []
      taxTypesData.forEach(val => {
        this.taxTypesData.push({ text: val.name, value: val.id })
      })
    },
    storeDataChanged(taxTypesData) {
      this.taxTypesData = []
      taxTypesData.forEach(val => {
        this.taxTypesData.push({ text: val.name, value: val.id })
      })
    },
    // eslint-disable-next-line no-unused-vars
    categoryDataChanged(categoriesDAta) {
    },
    saveData() {
      store.dispatch('app-product/updateProduct', this.productData)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    // fetch user
    const countryOptions = ref(null)
    const managersList = ref([])
    const productData = ref(null)
    const currencyOptions = ref([])
    const unitOptions = ref([])
    const categoriesData = ref([])
    const taxTypesData = ref([])
    store.dispatch('app/fetchTaxTypes', {})
      .then(response => {
        const arr = []

        response.data.data.forEach(val => {
          arr.push({ text: val.name, value: val.id })
        })
        taxTypesData.value = arr
      })
      .catch(error => {
        if (error.response.status === 404) {
          taxTypesData.value = null
        }
      })
    const fetchCategories = () => {
      store.dispatch('app-product/fetchCategories', { list_type: 'all' })
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({ text: val.name, value: val.id }))
          categoriesData.value = arr
        })
    }
    fetchCategories()
    store.dispatch('app/fetchCurrencyTypes', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ text: val.name, value: val.id }))
        currencyOptions.value = arr
      })
    store.dispatch('app/fetchUnitTypes', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ text: val.name, value: val.id }))
        unitOptions.value = arr
      })
    const onSubmit = () => {

    }
    const resetproductData = () => {
      productData.value = JSON.parse(JSON.stringify(props.blankProductData))
    }
    resetproductData()
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetproductData)

    return {
      onSubmit,
      countryOptions,
      refFormObserver,
      getValidationState,
      currencyOptions,
      categoriesData,
      managersList,
      taxTypesData,
      unitOptions,
      snowOption: {
        theme: 'snow',
      },
      priceInputConfig: {
        numeralDecimalScale: 4,
        numeral: true,
      },
      productData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

<template>
  <component :is="productData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="productData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="productData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Ürün Bilgileri') }}</span>
        </template>
        <product-edit-tab-information
          :blank-product-data="productData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Ürün Dosyaları') }}</span>
        </template>
        <product-edit-tab-documents
          v-if="productData"
          :product-id="productData.id"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import productStoreModule from '../productStoreModule'
import productEditTabInformation from './ProductEditTabInformation.vue'
import productEditTabDocuments from './ProductEditTabDocuments.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    router,
    productEditTabInformation,
    productEditTabDocuments,
  },
  setup() {
    const productData = ref(null)

    const PRODUCT_APP_STORE_MODULE_NAME = 'app-product'

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
    })
    store.dispatch('app-product/fetchProduct', { id: router.currentRoute.params.id })
      .then(response => {
        productData.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          productData.value = undefined
        }
      })

    return {
      productData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
